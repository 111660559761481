<template>
  <auth-content v-if="module">

    <el-header
      :titleSuffix="$route.name == 'module.archives' ? $t('common.archives_button') : null"
      slot="header"
      :favorites="!hashItem && hasAccessToContent === true && $route.name == 'module.index'"
    >
      <div slot="left" v-if="$route.hash !== ''">
        <el-header-back :back="back" />
      </div>
      <div slot="left" v-else-if="$route.name == 'module.archives'">
        <el-header-back />
      </div>
      <div slot="actions" v-if="hasAccessToContent === true">
        <el-header-action v-if="$route.name == 'module.index' && mode == 'index' && fields && userHasPermission($route.params.module, 'manage_module') && !hashItem" size="xs" icon="plus3" :label="$t(`modules.${$route.params.module}.${$route.params.page}.buttons.add`)" @click.native.prevent="initDataForm(false, true)" />
        <el-header-action v-if="$route.name == 'module.index' && mode == 'index' && isCurrentWorldOwner && !hashItem" size="xs" icon="archive" :label="$t('common.archives_button')" @click.native.prevent="$router.push({name: 'module.archives', params: {world: $route.params.world, module: $route.params.module, page: $route.params.page} })" classes="btn-grey text-dark" />
        <el-header-action v-if="$route.name == 'module.index' && mode == 'index' && fields && userHasPermission($route.params.module, 'manage_module') && hashItem && !hashItem.archived" size="xs" icon="pencil" :label="$t(`modules.${$route.params.module}.${$route.params.page}.buttons.edit`)" @click.native.prevent="initDataForm(true, true)" />
        <el-header-action v-if="$route.name == 'module.index' && mode == 'index' && fields && userHasPermission($route.params.module, 'manage_module') && hashItem && !hashItem.archived" size="xs" icon="trash" :rounded="true" @click.native.prevent="initDeleteForm()"  classes="btn-danger" />
        <el-header-action v-if="$route.name == 'module.index' && mode != 'index' && fields && userHasPermission($route.params.module, 'manage_module')" size="xs" icon="cross2" :label="$t(`common.cancel`)" @click.native.prevent="mode = 'index'; initDataForm()" classes="btn-secondary" />
      </div>
    </el-header>

    <div v-if="hasAccessToContent === false">
      <module-entity-need-access
        :entityModule="$route.params.module"
      />
    </div>

    <div class="row" v-if="hasAccessToContent === true">

      <div
        :class="{
          'col-md-8 col-xl-9 col-xxl-10': showSidebar,
          'col-12': !showSidebar,
        }"
      >

        <template v-if="mode == 'index'">

          <el-filters
            :search="(module.pages[$route.params.page].render && module.pages[$route.params.page].render.searchKeys) ? true : false"
            v-if="mode == 'index' && $route.hash === '' && filters && data && data.length > 1"
            :filters="filters"
          />
          <!-- //////////////////////////
          //////////// INDEX ////////////
          /////////////////////////// -->
          <div v-if="module.pages[$route.params.page].render && module.pages[$route.params.page].render.type && $route.hash === ''">
            <container
              :is="'module-index-' + module.pages[$route.params.page].render.type"
              :options="module.pages[$route.params.page].render.options"
              :data="data"
              :dataFiltered="dataFiltered"
              :module="module"
              :fields="fields"
              :config="config"
              :entity="module.pages[$route.params.page].render.data"
              :page="$route.params.page"
              :render="module.pages[$route.params.page].render"
              v-if="data.length > 0"
            >
              <el-alert type="warning">
                Render type <i>{{ 'module-index-' + module.pages[$route.params.page].render.type }}</i> is not available
              </el-alert>
            </container>
            <div v-else>
              <div v-if="$route.name == 'module.index'">
                <el-empty :title="$t(`modules.${$route.params.module}.${$route.params.page}.empty.empty_title`)" :text="$t(`modules.${$route.params.module}.${$route.params.page}.empty.empty_text`)"/>
                <div class="text-center mt-3" v-if="emptyAndOwner && userHasPermission($route.params.module, 'manage_module')">
                  <el-header-action size="xs" icon="plus3" :label="$t(`modules.${$route.params.module}.${$route.params.page}.buttons.add`)" @click.native.prevent="initDataForm(false, true)" />
                </div>
              </div>
              <div v-else-if="$route.name == 'module.archives'" >
                <el-empty :title="$t('common.archives_empty')" />
                <div class="text-center mt-3">
                  <el-header-action size="xs" icon="arrow-left7" :label="$t('common.back')" @click.native.prevent="$router.push({name: 'module.index', params: {world: $route.params.world, module: $route.params.module, page: $route.params.page} })" classes="btn-grey text-dark" />
                </div>
              </div>
            </div>
          </div>

          <!-- //////////////////////////
          ///////// ENTITY VIEW /////////
          /////////////////////////// -->
          <div v-else>

            <container
              :is="module.pages[$route.params.page].render.entity_render ? 'module-entity-viewmode-' + module.pages[$route.params.page].render.entity_render : 'module-entity-viewmode-default'"
              :data="hashItem"
              :fields="fields"
              :config="config"
              :module="module"
              :entity="module.pages[$route.params.page].render.data"
              :page="$route.params.page"
            />
          </div>

        </template>

        <!-- //////////////////////////
        ///////////// FORM ////////////
        /////////////////////////// -->
        <template v-else>

            <container
              :is="module.pages[$route.params.page].render.entity_form ? 'module-entity-form-' + module.pages[$route.params.page].render.entity_form : 'module-entity-form-default'"
              :data="hashItem"
              :dataForm="dataForm"
              :loading="dataForm_loading"
              :errors="formErrors"
              :submitForm="submitDataForm"
              :fields="fields"
              :config="config"
              :module="module"
              :entity="module.pages[$route.params.page].render.data"
              :page="$route.params.page"
              :mediasToRemove="mediasToRemove"
            />

        </template>
      </div>

      <div class="col-md-4 col-xl-3 col-xxl-2" v-if="showSidebar">

        <module-entity-template
          :saving="dataForm_loading"
          :entity="hashItem"
          v-if="hashItem && $route.hash !== '' && worldCanManageTemplates"
        />

        <module-entity-public-access
          :saving="dataForm_loading"
          :entity="hashItem"
          :publicLink="hashItem.public_link ? hashItem.public_link : null"
          v-if="$route.hash !== '' && !hashItem.archived && config.public_link_activatable"
        >
          <div class="small text-grey mb-1">{{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.config.public_link_description`) }}</div>
        </module-entity-public-access>

        <module-entity-informations
          :saving="dataForm_loading"
          :entity="hashItem"
          v-if="$route.hash !== ''"
        />

        <module-entity-status
          :saving="dataForm_loading"
          :entity="hashItem"
          v-if="hashItem && $route.hash !== '' && isCurrentWorldOwner"
        />

        <module-entity-permissions
          :saving="dataForm_loading"
          :entity="hashItem"
          :tribes="tribes"
          :users="users"
          :changeOwnerCallback="changeOwnerCallback"
          :changeAccessCallback="changeAccessCallback"
          :changeAccessAllCallback="changeAccessAllCallback"
          v-if="hashItem && $route.hash !== '' && !hashItem.archived"
        />

        <div v-if="mode == 'index' && module.pages[$route.params.page].pdf_export && $route.hash !== ''" class="pdfLoadingButton w-100 btn btn-outline-secondary shadow" @click="generatePdf()">
          <i class="icon-file-download2" v-if="!pdfLoading"></i>
          <loader v-else/>
          {{ $t('common.download_pdf') }}
          <span class="pdfLoadingProgress" v-if="pdfLoading || 1==1" :style="'width: ' + pdfProgress + '%;'"></span>
        </div>
      </div>

    </div>


    <el-modal size="sm" :title="$t(`modules.${this.$route.params.module}.${$route.params.page}.buttons.delete`)" id="module-data-delete-confirm" v-if="hasAccessToContent === true">
      <form @submit.prevent="removeData()">
        <div v-if="!remove_loading" class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_data_confirm">
            <span v-if="dataForm && dataForm.name">
              {{ $t(`modules.${this.$route.params.module}.${$route.params.page}.messages.delete_confirm`, { title: dataForm.name }) }}
            </span>
            <span v-else>{{ $t('common.are_you_sure') }}</span>
          </label>
        </div>
        <div class="text-center">
          <button :disabled="!remove_data_confirm" v-if="!remove_loading" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="remove_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>

  </auth-content>
</template>


<script>
const NEEDACCESS = 412
import Fuse from 'fuse.js'
import store from '../../store'
import * as jsPDF from 'jspdf'

import moduleIndexes from '@/components/module/indexes'
import moduleFields from '@/components/module/fields'
import moduleViewmodes from '@/components/module/viewmodes'
import moduleForms from '@/components/module/forms'

import {
  MODULE_REQUEST,
  MODULE_REQUEST_ARCHIVES,
  MODULE_STORE,
  MODULE_UPDATE,
  MODULE_REMOVE,
  MODULE_SET_DATA_FORM,
  MODULE_RESET_DATA_FORM
} from '../../store/mutations-types'

export default {
  name: 'Module',
  components: {...moduleIndexes, ...moduleViewmodes, ...moduleFields, ...moduleForms},
  tag: 'module',
  data () {
    return {
      hasAccessToContent: null,
      appName: process.env.VUE_APP_TITLE,
      pdfLoading: false,
      pdfProgress: 0,
      tmp: [],
      mediasToRemove: [],
      formErrors: [],
      remove_data_confirm: false,
      remove_loading: false,
      dataForm_loading: false,
      data: false,
      fields: false,
      config: false,
      searchValue: '',
      filters: false,
      mode: 'index',
    }
  },
  beforeRouteEnter (to, from, next) {

    const modules = store.getters['world/getModules']

    if (!modules) {
      next(false)
    }

    const moduleData = modules[to.params.module]

    let mutation = MODULE_REQUEST
    if (to.name == 'module.archives') {
      mutation = MODULE_REQUEST_ARCHIVES
    }
    store.dispatch('module/' + mutation, {
      worldId: to.params.world,
      moduleId: to.params.module,
      entityType: moduleData.pages[to.params.page].render.data,
    }).then(response => {

      next(vm => {

        vm.hasAccessToContent = true

        if (response.config) {
          vm.config = response.config
        }

        if (response.fields) {
          vm.fields = response.fields
          vm.initDataForm()
        }
        vm.data = []
        response.data.forEach( element => {
          vm.data.push(element)
        });

        vm.loadFilters()
      })
    }).catch(error => {
      const {status} = error.response
      if (status === NEEDACCESS) {

        next(vm => {
          vm.hasAccessToContent = false
        })

      }
    })

  },
  beforeRouteUpdate (to, from, next) {

    if (from.params.module !== to.params.module) {
      this.hasAccessToContent = null
      this.$store.dispatch('module/' + MODULE_REQUEST, {
        worldId: to.params.world,
        moduleId: to.params.module,
        entityType: this.modules[to.params.module].pages[to.params.page].render.data,
      }).then(response => {

        this.hasAccessToContent = true

        if (response.config) {
          this.config = response.config
        }

        if (response.fields) {
          this.fields = response.fields
        } else {
          this.fields = false
        }
        this.data = []
        response.data.forEach( element => {
          this.data.push(element)
        })

        this.mountPage()
        this.loadFilters()

        next()
      }).catch(error => {
        const {status} = error.response
        if (status === NEEDACCESS) {
          this.hasAccessToContent = false
          next()
        }
      })
    } else {
      next()
    }

  },
  methods: {
    mountPage () {
      this.mode = 'index'

      this.$nextTick(() => {
        if (
          this.isCurrentWorldOwner &&
          !this.alreadyOnboarded &&
          this.data &&
          this.data.length > 0 &&
          this.getBanners(this.currentRouteName, 'ONBOARDING')
        ) {
          this.$router.push(this.$route.path + '#' + this.data[0].id)
        }
        else if (this.emptyAndOwner && this.$route.name == 'module.index') {
          if (this.getBanners(this.currentRouteName, 'ONBOARDING')) {
            this.openModal('banner-onboarding-' + this.getBanners(this.currentRouteName, 'ONBOARDING')[0].id, true)
          }

          this.mode = "create"
          this.initDataForm(false, true)
        }
      })
    },
    generatePdf () {
      // if (this.pdfLoading) {
      //   return
      // }
      // Default export is a4 paper, portrait, using milimeters for units
      var pdf = new jsPDF('p','pt','a4')
      let html = '<div style="font-family: Roboto, Helvetica;">'

      var margins = {
          top: 20,
          bottom: 20,
          left: 40,
          width: 500
      };

      let module = this.$route.params.module
      let entity = this.module.pages[this.$route.params.page].render.data
      let fieldConfig = null
      let label = null

      html += '<h1>' + this.hashItem[this.config.name_field] + '</h1>'

      Object.keys(this.fields).forEach( fieldKey => {
        fieldConfig = this.fields[fieldKey]
        if (fieldConfig.type == 'heading' || this.hashItem[fieldKey] && this.hashItem[fieldKey] != '' && fieldKey !== this.config.name_field) {

          label = this.$te(`modules.${module}.entities.${entity}.fields.${fieldKey}.title`) === true ?
              this.$t(`modules.${module}.entities.${entity}.fields.${fieldKey}.title`) :
              this.$t(`modules.${module}.entities.${entity}.fields.${fieldKey}`)

          if (fieldConfig.type != 'heading' && fieldConfig.type != 'formpart') {
            html += '<div style="margin-left: 30px;">'
              html += '<div style="font-weight:bold; padding: 10px 0;">' + label + '</div>'

              if (fieldConfig.type === 'textarea') {
                html += '<div>' + this.hashItem[fieldKey].replace(/(?:\r\n|\r|\n)/g, '</div><div>') + '</div>'
              }
              else if (fieldConfig.type === 'text') {
                html += '<div>' + this.hashItem[fieldKey] + '</div>'
              }
              else if (fieldConfig.type === 'radios' || fieldConfig.type === 'select') {
                if (fieldConfig.reference) {
                  html += '<div>' + this.hashItem[fieldKey] + '</div>'
                } else {
                  let value = this.$te(`modules.${module}.entities.${entity}.fields.${fieldKey}.options`) === true ?
                                this.$t(`modules.${module}.entities.${entity}.fields.${fieldKey}.options.${this.hashItem[fieldKey]}`) :
                                this.$t(`modules.${module}.entities.${entity}.fields.${fieldKey}.${this.hashItem[fieldKey]}`)
                  html += '<div>' + value + '</div>'
                }
              }
            html += '</div>'

          } else {
            html += '<h3>> ' + label + '</h3>'
          }
        }

      })
      html = html.replace('–', '-')
      html = html.replace('“', '"')
      html = html.replace('”', '"')
      html = html.replace(/[\uE000-\uF8FF]/g, '');
      // all coords and widths are in jsPDF instance's declared units
      // 'inches' in this case
      pdf.setFont("Roboto");
      pdf.fromHTML(
        html + '</div>', // HTML string or DOM elem ref.
        margins.left, // x coord
        margins.top, { // y coord
            'width': margins.width, // max width of content on PDF
        },

        (dispose) => {
            // dispose: object with X, Y of the last line add to the PDF
            //          this allow the insertion of new lines after html
            pdf.save(this.hashItem[this.config.name_field] + '.pdf');
        },
        margins
      )

    },
    back () {
      this.mode == 'index' ? this.$router.go(-1) : this.mode = 'index'; this.initDataForm()
    },
    loadFilters () {
      let data = _.cloneDeep(this.data)
      this.filters = [
        {
          title: this.$t('moduleentity.template.title'),
          key: 'template',
          options: this.getFilterOptions(data, 'template', {'true': this.$t('common.yes')}, this.$t('common.no')),
        },
      ]

      if (!this.module.pages[this.$route.params.page].render || !this.module.pages[this.$route.params.page].render.filters) {
        return
      }
      this.module.pages[this.$route.params.page].render.filters.forEach( element => {
        let title = this.$t(`modules.${this.$route.params.module}.entities.${this.module.pages[this.$route.params.page].render.data}.fields.${element}`)
        if (title.title) {
          title = title.title
        }

        // Format filters option labels
        let optionLabels = {}
        if (this.$te(`modules.${this.$route.params.module}.entities.${this.module.pages[this.$route.params.page].render.data}.fields.${element}.options`)) {
          optionLabels = this.$t(`modules.${this.$route.params.module}.entities.${this.module.pages[this.$route.params.page].render.data}.fields.${element}.options`)
        } else if (this.fields && this.fields[element] && this.fields[element].type == 'user') {
          optionLabels = this.usersPlucked
        }

        this.filters.push({
          title: title,
          key: element,
          options: this.getFilterOptions(data, element, optionLabels),
        })
      })
    },
    formatDataFormForSending (data) {
      let formData = new FormData()

      formData.append('world_id', this.$route.params.world)

      Object.keys(data).forEach( (fieldKey) => {
        let fieldConfig = this.fields[fieldKey]
        let fieldValue = data[fieldKey]
        if (fieldConfig && fieldConfig.type && (fieldConfig.type == 'file' || fieldConfig.type == 'image') && fieldValue != null) { // store files
          if (!fieldConfig.multiple) { //single
            if (fieldValue instanceof File) {
              formData.append(fieldKey, fieldValue)
            }
          } else if (Array.isArray(fieldValue)) { // multiple
            fieldValue.forEach((file, index) => {
              if (file instanceof File) {
                formData.append(fieldKey + '[]', file)
              }
            })
          }
        }
        else if (fieldConfig && fieldConfig.type && (fieldConfig.type == 'file' || fieldConfig.type == 'image') && fieldValue == null) { // reset file if null
          formData.append(fieldKey, fieldValue)
        }
        else if (fieldConfig && fieldConfig.type && (fieldConfig.type == 'address')) {
          formData.append(fieldKey, JSON.stringify(fieldValue)) // reset file if null
        }
        else if (fieldValue !== null) {
          if (Array.isArray(fieldValue) && fieldValue.length > 0) {
            formData.append(fieldKey, JSON.stringify(fieldValue))
          } else if (typeof fieldValue === "object") {
            formData.append(fieldKey, JSON.stringify(fieldValue))
          } else if (fieldValue !== null) {
            formData.append(fieldKey, fieldValue)
          }
        }
      })

      // send medias id to remove
      this.mediasToRemove.forEach(mediaId => {
        formData.append('mediasToRemove[]', mediaId)
      });
      return formData
    },
    submitDataForm () {
      this.dataForm_loading = true
      let action = 'module/' + MODULE_STORE
      if (this.dataForm.id) {
        action = 'module/' + MODULE_UPDATE
      }

      let dataToSend = this.formatDataFormForSending(this.dataForm)

      this.$store.dispatch(action, {
        worldId: this.$route.params.world,
        moduleId: this.$route.params.module,
        entityType: this.module.pages[this.$route.params.page].render.data,
        data: dataToSend,
      }).then(data => {
        this.dataForm_loading = false
        if (this.dataForm.id) {
          // Update
          this.notifSuccess(this.$t(`modules.${this.$route.params.module}.${this.$route.params.page}.messages.edit_success`))
          let foundIndex = this.data.findIndex(elem => elem.id === data.id);
          this.$set(this.data, foundIndex, data);
        } else {
          // Push the new content to data array
          this.data.push(data)
          this.notifSuccess(this.$t(`modules.${this.$route.params.module}.${this.$route.params.page}.messages.add_success`))
        }
        this.loadFilters()
        this.mode = 'index'
        this.$store.commit('module/' + MODULE_RESET_DATA_FORM)
      })
      .catch(errors => {
        this.formErrors = []
        this.dataForm_loading = false
        if (errors.response.data && errors.response.data.errors) {
          this.formErrors = errors.response.data.errors
        }
        this.notifError(errors)

      })
    },
    removeData () {
      let dataId = this.dataForm.id

      this.remove_loading = true
      this.$store.dispatch('module/' + MODULE_REMOVE, {
        worldId: this.$route.params.world,
        moduleId: this.$route.params.module,
        entityType: this.module.pages[this.$route.params.page].render.data,
        data: this.dataForm
      }).then(data => {
        this.back()
        this.remove_loading = false
        this.notifSuccess(this.$t(`modules.${this.$route.params.module}.${this.$route.params.page}.messages.delete_success`))
        let foundIndex = this.data.findIndex(elem => elem.id === dataId)
        this.$delete(this.data, foundIndex)
        this.loadFilters()
        this.closeModal('module-data-delete-confirm')
        this.mode = 'index'
        this.initDataForm()
        this.remove_data_confirm = false
        this.$store.commit('module/' + MODULE_RESET_DATA_FORM)
      })
      .catch(error => {
        this.remove_loading = false
        this.notifError(error)
      })

    },
    initDeleteForm () {
      this.$store.commit('module/' + MODULE_SET_DATA_FORM, {...this.hashItem})
      this.openModal('module-data-delete-confirm')
    },
    initDataForm (edit = false, changeModeAfter = false) {
      this.dataForm = {}
      this.formErrors = []
      this.mediasToRemove = []

      // reset form
      let defaultValue
      Object.keys(this.fields).forEach( (element) => {

        if (this.fields[element].default) {
          // Predefined value
          defaultValue = this.fields[element].default
        } else if (
          this.fields[element].type === 'checkboxes' ||
          this.fields[element].type === 'tags' ||
          this.fields[element].type === 'address'
        ) {
          // Array field
          defaultValue = []
        } else {
          // Simple field
          defaultValue = ''
        }

        if (this.fields[element].multiple) {
          this.$set(this.dataForm, element, [])
          this.$set(this.dataForm[element], 0, defaultValue)
        } else {
          this.$set(this.dataForm, element, defaultValue)
        }

      })

      // Init edit
      if (edit) {
        Object.keys(this.hashItem).forEach( (hashItemKey) => {
          this.$set(this.dataForm, hashItemKey, this.hashItem[hashItemKey])
        })
      }

      if (changeModeAfter) {
        this.mode = edit ? 'edit' : 'create'

        // if (this.mode == 'create') {
        //   this.$nextTick(() => {
        //     console.info('EMIT mode', this.mode)
        //     this.$emit('module-form-reset')
        //   })
        // }
      }
    },
    changeOneDataFunction (key, value) {
      this.dataForm_loading = true

      if (!this.hashItem || !this.hashItem.id) {
        this.notifError
      }

      this.$store.dispatch('module/' + MODULE_UPDATE, {
        worldId: this.$route.params.world,
        moduleId: this.$route.params.module,
        entityType: this.module.pages[this.$route.params.page].render.data,
        data: {
          id: this.hashItem.id,
          [key]: value,
        },
      }).then(data => {
        this.dataForm_loading = false
        let foundIndex = this.data.findIndex(elem => elem.id === data.id);
        this.$set(this.data, foundIndex, data);
        this.loadFilters()
      })
      .catch(errors => {
        this.notifError(errors)
      })
    },
    changeOwnerCallback (newVal) {
      this.changeOneDataFunction('user_id', newVal)
    },
    changeAccessCallback (newVal) {
      this.changeOneDataFunction('access', newVal)
    },
    changeAccessAllCallback (newVal) {
      this.changeOneDataFunction('access_all', newVal)
    },
  },
  computed: {
    dataForm: {
      get () {
        return this.$store.getters['module/getDataForm']
      },
      set (val) {
        this.$store.commit('module/' + MODULE_SET_DATA_FORM, val)
      }
    },
    emptyAndOwner () {
      if (!this.data) {
        return false
      }
      return this.data.length == 0 && this.isCurrentWorldOwner
    },
    showSidebar () {
      return this.mode != 'index' || this.$route.hash !== '';
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates'];
    },
    modules () {
      return this.$store.getters['world/getModules']
    },
    users () {
      return this.$store.getters['world/getUsers']
    },
    tribes () {
      return this.$store.getters['world/getTribes']
    },
    usersPlucked () {
      let users = {}
      _.forEach(this.users, user => {
        users[user.id] = user.full_name || user.email
      })
      return users
    },
    moduleAndPage () {
      return this.$route.params.module + '/' + this.$route.params.page
    },
    module () {
      if (!this.modules) {
        return null
      } else {
        return this.modules[this.$route.params.module]
      }
    },
    dataFiltered () {
      if (!this.module.pages[this.$route.params.page].render || !this.module.pages[this.$route.params.page].render.searchKeys) {
        let dataToReturn = _.cloneDeep(this.data)
        return _.filter(dataToReturn, {
          'archived': this.$route.name == 'module.index' ? false : true
        })
      }


      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        let dataToReturn = this.applyDataFilters(_.cloneDeep(this.data), this.filters)
        return _.filter(dataToReturn, {
          'archived': this.$route.name == 'module.index' ? false : true
        })
      }
      return new Fuse(this.applyDataFilters(_.cloneDeep(this.data), this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: this.module.pages[this.$route.params.page].render.searchKeys
      }).search(searchValue)
    },
    hashItem () {
      if (this.data) {
        let foundIndex = this.data.findIndex(elem => elem.id == this.$route.hash.replace('#', ''))
        if (foundIndex != -1) {
          return this.data[foundIndex]
        }
      }
      return false
    }
  },
  mounted () {
    this.mountPage()
  },
  watch: {
    moduleAndPage (newVal) {
      this.mode = 'index'
      this.loadFilters()
    },
  }
}
</script>

<style lang="scss" scoped>
.pdfLoadingButton {
  overflow: hidden;
  position: relative;
  .pdfLoadingProgress {
    position: absolute;
    background: rgba(black, .15);
    transition: all .1s ease;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
  }
}
</style>
